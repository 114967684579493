export enum FieldType {
    LinkText = 0,

    ButtonAction = 1,

    IconAction = 2,

    Image = 3,

    EventStatusText = 4,

    AvatarField = 5,

    CheckboxAction = 6,

    Menu = 7,

    ImageWithText = 8,

    DynamicButtonAction = 9,

    LongText = 10,

    ColoredDropdown = 11,

    Normal = 12,

    DateTime = 13,

    //Names after the html element
    Pre = 14, 

    Date = 15,

    IconTooltip = 16
}
