import { Emit, ComponentBase } from "vue-facing-decorator";
import useVuelidate, { Validation, ValidationArgs } from "@vuelidate/core";
import BaseControl from "./complex.controls/base.control";
import { view } from "@/utilities/helpers/mobile.helper";

@ComponentBase
export default class DialogBaseModuleControl extends BaseControl {
    v$: Validation<ValidationArgs<unknown>, unknown> = useVuelidate() as never;

    component: string | null = null;
    componentData: object | null = null;
    isLoading = false;
    showDialog = true;

    get isMobileView(): boolean {
        return screen.width < 768;
    }

    get isTabletView(): boolean {
        return view.isTabletView();
    }

    @Emit("onSaved")
    saved(): void {
        (this.$parent as BaseControl).component = null;
    }

    @Emit("onClosed")
    closed(): void {
        (this.$parent as BaseControl).component = null;
    }
}
