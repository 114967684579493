import { AxiosInstance, AxiosResponse } from "axios";
import httpclient from "@/utilities/helpers/http.client.axios";
import OfferingModel from "@/models/models/offer/offering.model";
import OfferModel from "@/models/models/offer/offer.model";
import RequestAddOfferModel from "@/models/request.models/request.add.offer.model";

export class OfferManager {
    private readonly api: AxiosInstance = httpclient;

    async getOfferingsAsync(): Promise<OfferingModel[] | Error> {
        try {
            const response: AxiosResponse<OfferingModel[]> = await this.api.get(`/Offer/Offerings`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateOfferingsAsync(request: OfferingModel): Promise<OfferingModel | Error> {
        try {
            const response: AxiosResponse<OfferingModel> = await this.api.put(`/Offer/UpdateOffering`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async getOffersAsync(vesselId: number): Promise<OfferModel[] | Error> {
        try {
            const response: AxiosResponse<OfferModel[]> = await this.api.get(`/Offer/Offers/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async updateOfferAsync(request: OfferModel): Promise<OfferModel | Error> {
        try {
            const response: AxiosResponse<OfferModel> = await this.api.put(`/Offer/UpdateOffer`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async addOffersAsync(request: RequestAddOfferModel): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.post(`/Offer/AddOffers`, request);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async removeOfferAsync(id: number): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.delete(`/Offer/RemoveOffer/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    async synchronizeAsync(): Promise<number | Error> {
        try {
            const response: AxiosResponse<number> = await this.api.post(`/Offer/Synchronize`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}

export const offerManager = new OfferManager();
