import { Type } from "class-transformer";
import { VesselAdditionalInfoModel } from "./vessel.additional.info.model";
import { VesselNameModel } from "./vessel.name.model";
import { VesselModelModel } from "./vessel.model.model";
import { ContactModel } from "./contact.model";
import { UserModel } from "./user.model";
import { OperatorModel } from "../response.models/operator.model";
import { CountryModel } from "@/models/models/country.model";
import ResponseVesselMultiMediaModel from "@/models/response.models/response.vessel.multi.media.model";
import CustomerSegmentModel from "@/models/models/customer.segment.model";
import ApplicationModel from "@/models/models/application.model";
import VesselJetModel from "./vessel.jet.model";
import ProductTypeModel from "./product.type.model";
import VesselUltraJetModel from "./vessel.ultra.jet.model";

export class VesselFullModel {
    id?: number;

    name!: string;

    vesselNumber!: string;

    defaultServiceProvider!: ContactModel | null;

    serviceProviders!: ContactModel[] | null;

    vesselImageSource?: string;

    annualRunningHours!: number;

    currentOperator!: OperatorModel;

    currentCountry!: CountryModel;

    currentCustomerSegment?: CustomerSegmentModel;

    currentCustomerSubSegment?: CustomerSegmentModel;

    currentApplication?: ApplicationModel;

    @Type(() => Date) dockingDateTime!: Date;

    vesselAdditionalInfo = new VesselAdditionalInfoModel();

    responsibleMjpUsers!: UserModel[];

    responsibleSpUsers!: UserModel[];

    multiMedia!: ResponseVesselMultiMediaModel[];

    @Type(() => Date) warrantyEndDate?: Date;

    get warrantyEndDateString(): string {
        return this.warrantyEndDate ? this.warrantyEndDate.toLocaleDateString() : "";
    }

    hasWarrantyExpired!: boolean;

    warrantyStatusText?: string;

    @Type(() => Date) extendedWarrantyEndDate?: Date;
    
    hasExtendedWarrantyExpired!: boolean;

    _IsPhotoChanged = false;

    concessionInformation?: string;

    jets?: VesselJetModel[];

    productType?: ProductTypeModel;

    isHighClassVessel: boolean = false;

    ultraJets?: VesselUltraJetModel[];

    constructor() {
        this.vesselAdditionalInfo.vesselNames = [
            { version: 3, name: "" } as VesselNameModel,
            { version: 2, name: "" } as VesselNameModel,
            { version: 1, name: "" } as VesselNameModel
        ];
        this.vesselAdditionalInfo.vesselModels = [new VesselModelModel()];
    }
}
